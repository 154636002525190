<template>
  <div class="container" v-if="isFinish">
    <img class="ronge-logo" src="../assets/images/rongE_logo.png" />
    <img class="bg" src="../assets/images/index_bg.png" />
    <!-- 注册表单 -->
    <div class="container-item register">
      <img class="weitu" src="../assets/images/weitu.png" />
      <h5 class="tips">提示：平台需要使用您的手机号和验证码进行创建账号。</h5>
      <van-row class="tel">
        <van-col span="24">
          <van-field
            v-model="tel"
            type="tel"
            maxlength="11"
            placeholder="请输入手机号码"
          />
        </van-col>
      </van-row>
      <van-row class="sms">
        <van-col span="24">
          <van-field
            v-model="sms"
            center
            placeholder="请输入短信验证码"
            type="digit"
            maxlength="4"
          >
            <template #button>
              <van-button
                v-if="isSend"
                :disabled="send_disabled_code"
                @click="sendCode"
              >
                {{ codeTips }}
              </van-button>
              <van-button v-else :disabled="disabled" @click="sendCode">{{
                sendBtnTips
              }}</van-button>
            </template>
          </van-field>
        </van-col>
      </van-row>
      <van-row class="xieyi">
        <van-col span="24">
          <van-checkbox v-model="checked">
            我已阅读并同意
            <span class="con" @click.stop="showProp(2)">《注册协议》</span> 和
            <span class="con" @click.stop="showProp(1)">《隐私政策》</span>
          </van-checkbox>
        </van-col>
      </van-row>
      <van-row class="now">
        <van-col span="24">
          <van-field>
            <template #button>
              <van-button :disabled="send_now_disabled" @click="handleNow">
                立即申请
              </van-button>
            </template>
          </van-field>
        </van-col>
      </van-row>
    </div>
    <div class="container-item tip">
      <van-row>
        <van-col
          v-for="(item, index) in list"
          :key="index"
          span="12"
          class="tip-item"
          :style="index === 2 || index === 3 ? { marginBottom: 0 } : {}"
        >
          <img class="tip-img" :src="item.img" />
          <div class="tip-div">
            <h4 class="tip-title">{{ item.title }}</h4>
            <span class="tip-sub">{{ item.subtitle }}</span>
          </div>
        </van-col>
      </van-row>
    </div>
    <span class="bottom">
      贷款有风险，借款需谨慎
      <br />
      请根据个人能力合理贷款
      <br />
      综合年化利率最低6%-最高24%，具体以审核为准
      <br />
      重庆市渝中区科融小额贷款有限责任公司
      <br />
      渝ICP备18004509号
    </span>

    <van-popup
      v-model="show"
      round
      position="bottom"
      safe-area-inset-bottom
      :style="{ height: '30%' }"
      @close="handleClose"
    >
      <div v-html="showContent" />
    </van-popup>
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "Register",
  data() {
    return {
      client: this.MixinClientBrowser(),
      isFinish: false,
      checked: false,
      tel: "",
      sms: "",
      channel: this.$route.query.source,
      cookieId: this.$store.state.cookieId,
      list: [
        {
          img: require("../assets/images/icon_ed.png"),
          title: "额度高",
          subtitle: "额度最高可达50000元",
        },
        {
          img: require("../assets/images/icon_fk.png"),
          title: "放款快",
          subtitle: "当日即可完成评估",
        },
        {
          img: require("../assets/images/icon_lx.png"),
          title: "利息低",
          subtitle: "最低日息0.02%",
        },
        {
          img: require("../assets/images/icon_hk.png"),
          title: "还款灵活",
          subtitle: "0抵押 0担保",
        },
      ],
      show: false,
      codeTips: "获取验证码",
      sendBtnTips: "",
      disabled: true,
      isSend: true,
      seconds: 60,
      ysxy: "",
      zcxy: "",
      showContent: "",
    };
  },
  created() {
    this.isJump();
  },
  computed: {
    send_disabled_code() {
      return !this.tel;
    },

    send_now_disabled() {
      return this.tel && this.sms && !this.checked;
    },
  },
  methods: {
    isJump() {
      API_Common.isJump({
        channel: this.channel,
        cookieId: this.cookieId,
      }).then((res) => {
        if (200 === res.code) {
          let type = res.data.type;
          if (2 === type) {
            window.location.href = res.data.linkUrl;
          } else {
            this.isFinish = true;

            Promise.all([
              API_Common.getContent({ pageType: 6 }),
              API_Common.getContent({ pageType: 2 }),
            ]).then((res) => {
              this.ysxy = res[0].data.content;
              this.zcxy = res[1].data.content;
            });
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    showProp(type) {
      this.show = true;
      this.showContent = 2 === type ? this.zcxy : this.ysxy;
    },

    handleClose() {
      this.showContent = "";
    },

    sendCode() {
      let reg = /^1[0-9]{10}$/;
      if (!reg.test(this.tel)) {
        this.$toast.fail("请输入正确的手机号");
      } else {
        API_Common.sendSmsCode(this.tel).then((res) => {
          if (200 === res.code) {
            this.$toast.success("发送成功");
            this.isSend = false;
            this.sendBtnTips = `${this.seconds}s`;
            let interval = setInterval(() => {
              this.seconds--;
              this.sendBtnTips = `${this.seconds}s`;
              if (this.seconds < 1) {
                this.codeTips = "重新获取";
                this.isSend = true;
                clearInterval(interval);
              }
            }, 1000);
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },

    handleNow() {
      let params = {
        mobile: this.tel,
        code: this.sms,
        channel: this.channel,
      };
      API_Common.login(params).then((res) => {
        if (res.code === 200) {
          this.$store.dispatch("setToken", res.data);
          if (this.channel === "guomei1" && this.client === "android") {
            this.$router.push({
              name: "Certification",
              query: {
                source: this.channel,
              },
            });
          } else {
            this.$router.push({
              name: "Wallet",
              query: {
                source: this.channel,
              },
            });
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding: 6.98rem 0.36rem 0;
  height: 100vh;
  overflow: auto;
}

.ronge-logo {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  width: 1.6rem;
  height: 0.38rem;
  margin-top: 0.4rem;
  margin-top: 0.2rem;
  margin-left: calc(-25% + 0.8rem);
  margin-right: 0;
}

.bg {
  width: 100%;
  height: 20.46rem;
  position: absolute;
  top: 0;
  left: 0;
}
.container-item {
  background: #fff;
  border-radius: 0.24rem;
  padding: 0 0.3rem;
  position: relative;
  z-index: 1;
}
.register {
  padding-top: 0.22rem;
  padding-bottom: 0.56rem;
  margin-bottom: 0.36rem;
}
.weitu {
  width: 100%;
  height: 1.64rem;
}
.tips {
  font-size: 0.24rem;
  font-family: PingFangSC, PingFangSC-Regular !important;
  font-weight: 400;
  color: #c3c2c6;
  margin-bottom: 0.24rem;
}
.tel {
  margin-bottom: 0.3rem;
}
.sms {
  margin-bottom: 0.48rem;
}
.xieyi {
  font-size: 0.24rem;
  font-weight: 400;
  color: #959595;
  margin-bottom: 0.48rem;
}
.con {
  color: #fb8f0b;
}
.now {
  ::v-deep {
    .van-field__control {
      display: none;
    }
    .van-field__body,
    .van-field__button {
      padding-left: 0;
    }

    .van-field__button,
    .van-button--default {
      width: 100%;
      height: 0.9rem;
      background: linear-gradient(90deg, #ffbb31, #fb8302);
      font-size: 0.32rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.tip {
  padding: 0.48rem 0.36rem;
}
.tip-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.64rem;
}
.tip-img {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.1rem;
}
.tip-div {
  height: 0.8rem;
  display: flex;
  flex-direction: column;
}
.tip-title {
  font-size: 0.3rem;
  font-weight: 500;
  color: #f88b2d;
}
.tip-sub {
  font-size: 0.24rem;
  font-weight: 400;
  color: #462b1b;
}
::v-deep {
  .van-cell {
    height: 0.96rem;
    padding: 0;
    background: #f9f9f9;
    border-radius: 0.08rem;
  }
  .van-field__body {
    height: 100%;
    padding-left: 0.26rem;
  }
  .van-button {
    height: 0.96rem;
    border: none;
    background: linear-gradient(90deg, #ffbb31, #fb8302);
    font-size: 0.28rem;
    font-weight: 400;
    color: #ffffff;
    min-width: 2.22rem;
  }
  .van-checkbox__icon {
    height: auto;
  }
  .van-checkbox__icon--checked .van-icon {
    background: #fb8f0b;
    border-color: #fb8f0b;
  }
  .van-icon {
    width: 0.3rem;
    height: 0.3rem;
  }
  .van-icon-success:before {
    width: 100%;
    height: 100%;
    position: absolute;
    left: -0.03rem;
    top: -0.05rem;
    color: #fff;
  }
  .van-popup {
    height: 50% !important;
    font-size: 0.25rem;
    padding: 0.2rem;
    line-height: 0.4rem;
    text-align: justify;
  }
}
.bottom {
  margin-top: 0.36rem;
  position: relative;
  z-index: 1;
  font-size: 0.24rem;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 0.34rem;
  display: block;
}
</style>
